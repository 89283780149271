import { VentureCapitalFirmFilterDto } from "api/vc-firms";
import { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import MailSvg from "svg/mail.svg";

export interface CardVcFirmProps {
  className?: string;
  vc: VentureCapitalFirmFilterDto;
  size?: "large" | "normal";
}

const CardVcFirm: FC<CardVcFirmProps> = ({
  className = "",
  size = "normal",
  vc,
}) => {
  const { id, vcFirm, contactInformation, industries, logo } = vc;
  return (
    <Link
      to={`/vc-firm/${id}/`}
      className={`nc-CardVcFirm flex items-center ${className}`}
      data-nc-id="CardVcFirm"
    >
      <NcImage containerClassName={`flex-shrink-0 ${size === "large" ? "w-20 h-20" : "w-12 h-12"} rounded-lg mr-4 overflow-hidden`} src={logo}/>
      <div className="flex flex-row justify-between items-center w-full">
        <div>
          <h2 className={`${size === "large" ? "text-lg" : "text-base"} nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}>
            {vcFirm}
          </h2>
          <span className={`${size === "large" ? "text-sm" : "text-xs"} block mt-[2px] text-neutral-500 dark:text-neutral-400`}>
            {industries?.split(', ').splice(0, 2).map((industry, index) => (
              <span key={index} className="inline-block bg-blue-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">{industry}</span>
            ))}
          </span>
        </div>
        {contactInformation ? <MailSvg color="gray" /> : null}
      </div>
    </Link>
  );
};

export default CardVcFirm;
