import React, { useEffect, useState } from 'react';
import { firestore } from '../../firebase'
import { query, collection, where, getDocs } from 'firebase/firestore';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';
import FooterNav from 'components/FooterNav';
import moment from 'moment';
import Heading from 'components/Heading/Heading';
import { Navigate, useNavigate } from "react-router-dom";

interface Conversation {
    id: string;
    lastMessage: string;
    sentAt: string;
    participants: string[];
    participantsMetaData: {
        [key: string]: {
            avatar: string;
            fullName: string
        };
    }
    status: {
        [key: string]: 'seen' | 'not-seen';
    };
}


function PageMessages() {
    const navigator = useNavigate();
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [messages, setMessages] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState<Conversation>();

    useEffect(() => {
        const getConversations = async () => {
            if (!authStore.currentUser?.id) {
                return
            }
            const userConversations = await fetchUserConversations(authStore?.currentUser?.id);
            console.log(userConversations);

            setConversations(userConversations);
        };

        getConversations();
    }, []);

    // useEffect(() => {
    //     if (selectedConversation) {
    //         const getMessages = async () => {
    //             const conversationMessages = await fetchMessages(selectedConversation.id);
    //             // setMessages(conversationMessages);
    //         };

    //         getMessages();
    //     }
    // }, [selectedConversation]);

    const fetchUserConversations = async (userId: string): Promise<Conversation[]> => {
        const q = query(
            collection(firestore, 'conversations'),
            where('participants', 'array-contains', userId)
        );
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            lastMessage: doc.data().lastMessage,
            sentAt: doc.data().sentAt,
            participants: doc.data().participants,
            participantsMetaData: doc.data().participantsMetaData,
            status: doc.data().status
        }));
    };

    const fetchMessages = async (conversationId: string) => {
        const messagesCollection = collection(firestore, `conversations/${conversationId}/messages`);
        const messagesSnapshot = await getDocs(messagesCollection);
        return messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    };

    const getReciverName = (conversation: Conversation) => {
        if (!conversation?.participantsMetaData) {
            return null
        }
        const [receiverId] = Object.keys(conversation.participantsMetaData).filter(id => id !== authStore.currentUser?.id);

        if (receiverId) {
            return conversation.participantsMetaData[receiverId].fullName;
        } else {
            return '';
        }
    }

    const getReciverAvatar = (conversation: Conversation) => {
        if (!conversation?.participantsMetaData) {
            return null
        }
        const [receiverId] = Object.keys(conversation.participantsMetaData).filter(id => id !== authStore.currentUser?.id);

        if (receiverId) {
            return conversation.participantsMetaData[receiverId].avatar;
        } else {
            return '';
        }
    }

    const getReciverId = (conversation: Conversation) => {
        if (!conversation?.participantsMetaData) {
            return null
        }

        const [receiverId] = Object.keys(conversation.participantsMetaData).filter(id => id !== authStore.currentUser?.id);
        return receiverId;
    }

    return (
        <div className='container mt-2'>
            <Heading
                children="Chats"
                desc={``}
                className="mb-10 lg:mb-16 text-neutral-900 dark:text-neutral-50"
                isCenter={true}
            />
            <div className="flex flex-col h-screen">
                <ul className="max-w-md divide-y divide-gray-200 dark:divide-gray-700">
                    {conversations.map((conversation) => (
                        <li
                            key={conversation.id}
                            onClick={() => navigator(`/chat/${getReciverId(conversation)}`)}
                            className="py-3 sm:py-4"
                        >
                            <div className="flex items-center space-x-4 rtl:space-x-reverse">
                                <div className="flex-shrink-0">
                                    <img className="w-8 h-8 rounded-full" src={getReciverAvatar(conversation) || ''} alt="Neil image"/>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {getReciverName(conversation)}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {conversation.lastMessage}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-xs font-semibold text-gray-900 dark:text-white">
                                    {moment(conversation.sentAt).format('h:mm a')}
                                </div>
                            </div>
                            {/* <hr className="my-1 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" /> */}
                        </li>
                    ))}
                </ul>
            </div>
            <FooterNav />
        </div>
    );
}

export default observer(PageMessages)