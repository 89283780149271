import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { observer } from "mobx-react";
import { searchFormStore } from "stores/Views/SearchFormStore";
import { TripDetails } from "interface/trip.interface";
import { authStore } from "stores/AuthStore";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: TripDetails[];
  heading?: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (currentTab: string, trip: TripDetails) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "",
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (currentTab: string, trip: TripDetails) => {
    console.log("currentTab", currentTab, trip);
    
    onClickTab && onClickTab(currentTab, trip);
    setTabActiveState(currentTab);
  };

  const openModalLargeScreen = () => searchFormStore.setShowHeroSearch('guests')
  const openModalSmallScreen = () => searchFormStore.setShowMobileModal(true)

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          <NavItem
            key={1}
            isActive={tabActiveState === 'Explore'}
            onClick={() => handleClickTab('Explore', { toCountry: authStore.currentUser?.profile?.country } as TripDetails)}
          >
            Explore
          </NavItem>
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item.toCountry}
              onClick={() => handleClickTab(item.toCountry, item)}
            >
              {item.toCountry}
            </NavItem>
          ))}
        </Nav>
        <span className="flex-shrink-0 hidden lg:block">
          <ButtonSecondary className="!leading-none" onClick={openModalLargeScreen}>
            <span className="">Add trip</span>
            <i className="las la-plus text-xl"></i>
          </ButtonSecondary>
        </span>
        <span className="flex-shrink-0 lg:hidden">
          <ButtonSecondary className="!leading-none" onClick={openModalSmallScreen}>
            <i className="las la-plus text-xl"></i>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default observer(HeaderFilter);
