import axios, { AxiosResponse } from 'axios'
import { FirebaseUser } from 'firebase';
import { User } from 'stores/AuthStore';

export async function registerWithGoogleProvider(user: FirebaseUser): Promise<AxiosResponse<User>> {
    const [data] = user.providerData
    const fullName = data.displayName;
    const email = data.email;
    const avatar = data.photoURL;
    const providerId = user.uid;
    const provider = data.providerId;
    const acceptedTerms = true;

    const body = {
        email,
        providerId,
        provider,
        acceptedTerms,
        profile: {
            fullName,
            avatar
        }
    }
    
    return axios.post(`${process.env.REACT_APP_API_URL}/authentication/register/provider`, body, { withCredentials: true })
}