import { registerWithGoogleProvider } from 'api/register';
import { getUser } from 'api/user';
import {
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import * as _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import setAuthorizationHeader from 'utils/setAuthorizationHeader';
import { auth } from '../firebase';
import { updateUserAvatar, updateUserProfile, updateUserProfileOnBoardingDone } from 'api/profile';

export interface UserProfile {
    id: string;
    fullName: string;
    avatar: string | undefined;
    bgImg: string | null;
    companyName: string | null;
    phone: string;
    birthDate: Date;
    placeDescription: string;
    placeId: string;
    placeKeywords: string[];
    country: string;
    city: string;
    state: string;
    lat: string;
    long: string;
    bio: string;
    interests: string[];
    industry: string;
    role: string;
    hasCompletedOnboarding: boolean;
    verifiedAccount: boolean;
    goals: string[];
}

export interface User {
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    id: string;
    email: string;
    token: string | null;
    provider: string;
    providerId: string;
    acceptedTerms: boolean | null;
    profile: UserProfile;
}


export interface UpdateProfileDto {
    id: string;
    fullName?: string;
    companyName?: string;
    industry?: string;
    role?: string;
    placeDescription?: string;
    interests?: string[];
    avatar?: string;
    birthDate?: Date;
    phone?: string;
    bio?: string;
    goals?: string[];
}

class AuthStore {
    currentUser: User | null = null;
    loading = true;

    constructor() {
        makeAutoObservable(this);

        // Initialize user state
        this.initUserState();
    }

    async initUserState() {
        onAuthStateChanged(auth, async (firebaseUser) => {
            console.log('Is logged in:', firebaseUser);

            if (firebaseUser) {
                try {
                    const accessToken = await firebaseUser.getIdToken();
                    setAuthorizationHeader(accessToken);
                    const { data: user } = await getUser();
                    runInAction(() => {
                        this.currentUser = user;
                        this.loading = false;
                    });
                } catch (error) {
                    runInAction(() => {
                        this.currentUser = null;
                        this.loading = false;
                    });
                    // Handle sign-out or redirect to login
                }
            } else {
                runInAction(() => {
                    this.currentUser = null;
                    this.loading = false;
                });
                // Handle redirect to login if needed
            }
        });
    }

    async updateUser(updatedUser: Partial<User>) {
        if (_.isEmpty(this.currentUser)) {
            return
        }

        await updateUserProfileOnBoardingDone(updatedUser);

        if (this.currentUser && this.currentUser.profile) {
            this.currentUser.profile = {
                ...this.currentUser.profile,
                ...updatedUser,
            };
        }
    }

    async updateProfile(updatedUser: UpdateProfileDto) {
        if (_.isEmpty(this.currentUser)) {
            return
        }

        await updateUserProfile(updatedUser);

        if (this.currentUser && this.currentUser.profile) {
            this.currentUser.profile = {
                ...this.currentUser.profile,
                ...updatedUser,
            };
        }
    }

    async googleSignIn() {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const { data: user } = await registerWithGoogleProvider(result.user);

        if (user) {
            this.currentUser = user;
        }
        // Update user state or navigate post-login
    }

    async logOut() {
        await signOut(auth);
        // this.currentUser = null;
        // Handle post-logout navigation or state cleanup
    }

    async sendSignInLinkToEmailHandler(email: string) {
        window.localStorage.setItem('emailForSignIn', email);
        const actionCodeSettings = {
            url: `${window.location.origin}/sign-in-completed`,
            handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    }

    isSignInWithEmailLinkHandler(emailLink: string) {
        return isSignInWithEmailLink(auth, emailLink);
    }

    async signInWithEmailLinkHandler(email: string, emailLink: string) {
        const result = await signInWithEmailLink(auth, email, emailLink);
        await registerWithGoogleProvider(result.user);
        window.localStorage.removeItem('emailForSignIn');
        // Handle navigation or state update post-sign-in
        if (result) {
            return true
        }
        return false
    }

    setLoading(status: boolean) {
        this.loading = status;
    }

    async updateAvatar(formData: FormData) {
        const { data: avatarUrl } = await updateUserAvatar(formData)
        if (this.currentUser) {
            this.currentUser.profile.avatar = avatarUrl
        }
    }
}

export const authStore = new AuthStore();
