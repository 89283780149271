import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { sendOnboardingData } from 'api/profile';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { authStore } from 'stores/AuthStore';
import { onboardingStore } from 'stores/OnboardingStore';
import CommonLayout from "./CommonLayout";
import { reaction } from 'mobx';

export const interests: { [key: string]: { emoji: string; topics: string[] } } = {
    Business: {
        emoji: '💼',
        topics: [
            'entrepreneurship', 'marketing', 'sales', 'consulting',
            'e-commerce', 'retail', 'real estate', 'business strategy',
            'startups', 'leadership', 'management', 'human resources',
            'operations', 'supply chain', 'business development',
            'hospitality', 'aviation'
        ]
    },
    'Investing & Finance': {
        emoji: '💰',
        topics: [
            'stock market', 'investing', 'personal finance', 'financial planning',
            'wealth management', 'cryptocurrency', 'blockchain', 'venture capital',
            'private equity', 'hedge funds', 'economic trends', 'financial analysis',
            'retirement planning', 'real estate investment', 'insurance'
        ]
    },
    Lifestyle: {
        emoji: '🌟',
        topics: [
            'travel', 'food and drink', 'health and wellness', 'fitness',
            'fashion', 'home decor', 'parenting', 'relationships',
            'personal development', 'mindfulness', 'spirituality', 'hobbies',
            'outdoor activities', 'self-care', 'luxury lifestyle'
        ]
    },
    'Science & Tech': {
        emoji: '🔬',
        topics: [
            'ai', 'biohacking', 'machine learning', 'product design',
            'programming languages', 'vr/ar', 'product management',
            'robotics', 'fintech', 'data science', 'life sciences',
            'visual design', 'cybersecurity', 'quantum computing', 'biotech',
            'nanotechnology', 'space exploration', 'renewable energy', 'climate tech'
        ]
    },
    'Social Causes': {
        emoji: '🌍',
        topics: [
            'environmental sustainability', 'climate change', 'human rights',
            'social justice', 'education', 'healthcare access',
            'animal welfare', 'poverty alleviation', 'community development',
            'racial equality', 'mental health advocacy',
            'volunteering', 'nonprofits', 'philanthropy'
        ]
    },
    'Sports & Entertainment': {
        emoji: '🎭',
        topics: [
            'sports', 'movies', 'television', 'music', 'theater', 'gaming', 'esports', 'comedy',
            'books', 'podcasts', 'arts and culture', 'dance',
            'festivals', 'events', 'concerts'
        ]
    }
};


const OnBoardingStep4: React.FC = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [forceUpdate, setForceUpdate] = useState<number>(0);

    useEffect(() => {
        // Reaction to rerender the component when onboardingStore.interests changes
        const dispose = reaction(
            () => onboardingStore.interests.slice(), // Observes the 'interests' array
            () => {
                // Trigger a re-render by updating the local state
                setForceUpdate((prev) => prev + 1);
            }
        );

        // Clean up the reaction when the component unmounts
        return () => dispose();
    }, [forceUpdate]);

    const toggleTopic = (interest: string) => {
        onboardingStore.setInterests(interest);
    };

    const handlePrevStep = () => onboardingStore.setOnboardingPrevStep()

    const completeOnboarding = async () => {
        if (onboardingStore.industry.length === 0) {
            toast.error('Please complete the form')
            return
        }

        const onboardingData = onboardingStore.getOnboardingData;

        try {
            const { data: updatedProfile } = await sendOnboardingData(onboardingData);

            if (authStore.currentUser) {
                authStore.currentUser.profile = updatedProfile
                authStore.updateUser(authStore.currentUser)
            }

            setIsDialogOpen(true);
            // Handle the response, update store if needed
            console.log('Onboarding data sent successfully');
        } catch (error) {
            console.error(error);
            toast.error('Something went wrong')
            // Handle errors, such as displaying an error message to the user
        }
    };

    const getBGColor = (topic: string) => {
        return onboardingStore.interests.includes(topic)
            ? 'bg-primary-6000 text-white'
            : 'bg-gray-200 text-gray-800 border-gray-300 hover:bg-gray-300';
    };
    return (
        <CommonLayout
            index="5"
            onContinue={completeOnboarding}
            onBack={handlePrevStep}
        >
            <div className="w-full max-w-md mx-auto bg-white text-gray-900 rounded-lg shadow-lg p-6">
                <h2 className="text-xl text-center font-semibold">Tell us your interests!</h2>
                <p className="text-center text-gray-600 mb-6">Choose your top interests!</p>
                {Object.keys(interests).map((interset) => (
                    <Disclosure key={interset}>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex justify-between w-full px-4 py-4 mt-2 text-sm font-medium text-left text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                                    <span>{`${interests[interset].emoji} ${interset}`}</span>
                                    {open ? (
                                        <ChevronUpIcon className="w-5 h-5 text-gray-900" />
                                    ) : (
                                        <ChevronDownIcon className="w-5 h-5 text-gray-900" />
                                    )}
                                </Disclosure.Button>
                                <Disclosure.Panel
                                    key={`${interset}-${onboardingStore.interests.length}`} // Changing key forces re-render
                                    className="px-4 pt-4 pb-2 text-sm text-gray-700 bg-gray-50 border border-gray-300 rounded-lg mt-2">
                                    <div className="flex flex-wrap gap-2">
                                        {interests[interset].topics.map((topic) => (
                                            <button
                                                key={topic}
                                                className={`px-3 py-1 border rounded-full text-sm ${getBGColor(topic)}`}
                                                onClick={() => toggleTopic(topic)}
                                            >
                                                {topic}
                                            </button>
                                        ))}
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </div>

        </CommonLayout>
    );
};

export default observer(OnBoardingStep4);
