import { ConnectionResponse } from 'api/connections';
import React from 'react';
import { Link } from 'react-router-dom';
import { User } from 'stores/AuthStore';

interface ConnectionButtonsProps {
    connection: ConnectionResponse | null;
    user: User | null;
    onHandshake: () => void;
    onCancel: () => void;
    onConfirm: () => void;
    onDecline: () => void;
    isLoading: boolean;
}

const ConnectionButtons: React.FC<ConnectionButtonsProps> = ({
    connection,
    user,
    onHandshake,
    onCancel,
    onConfirm,
    onDecline,
    isLoading
}) => {
    
    const _renderLoading = () => {
        return (
            <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="3"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        );
    };

    if (isLoading) {
        return (
            <button
                disabled={isLoading}
                className="relative h-auto inline-flex items-center justify-center rounded-full transition-colors"
                type="button"
            >
                {isLoading && _renderLoading()}
                Loading
            </button>
        );
    }

    console.log(connection);
    
    if (!connection || !user) {
        return <button
            type="button"
            onClick={onHandshake}
            className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
        >
            Handshake
            <i className="ml-1 las la-handshake w-3 h-3 me-2"></i>
        </button>
    }

    return (
        <div className="inline-flex rounded-md shadow-sm" role="group">
            {!connection && (
                <button
                    type="button"
                    onClick={onHandshake}
                    className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                >
                    Handshake
                </button>
            )}
            {connection && connection.status === 'pending' && connection.sender.id === user.id && (
                <button
                    type="button"
                    onClick={onCancel}
                    className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                >
                    Cancel Request
                </button>
            )}
            {connection && connection.status === 'pending' && connection.receiver.id === user.id && (
                <>
                    <button
                        type="button"
                        onClick={onConfirm}
                        className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-s-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                    >
                        Confirm
                    </button>
                    <button
                        type="button"
                        onClick={onDecline}
                        className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-e-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                    >
                        Decline
                    </button>
                </>
            )}
            {connection && connection.status === 'approved' && (
                <Link to={`/chat/${connection.sender.id === user.id ? connection?.receiver?.id :  connection.sender.id}`} className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 ">Go To Chat</Link>
            )}
            {connection && connection.status === 'declined' && (
                <button className="px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 text-gray-900">{connection.sender.id === user.id ? 'Blocked' :  'Declined'}</button>
            )}
        </div>
    );
};

export default ConnectionButtons;
