import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { getAnalytics } from "firebase/analytics";

export interface FirebaseUser {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  emailVerified: boolean;
  phoneNumber: string | null;
  isAnonymous: boolean;
  providerData: ProviderData[];
  refreshToken: string;
  // Add any other properties you need from the Firebase user object
}

interface ProviderData {
  providerId: string;
  uid: string;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null
}


const app = firebase.initializeApp({
  apiKey: "AIzaSyA0QPoGZFgMVdkD5JH9Jt1FyjNfu4cwvXQ",
  // authDomain: "meetwiv.com",
  authDomain: "meet-8a8ee.firebaseapp.com",
  projectId: "meet-8a8ee",
  storageBucket: "meet-8a8ee.appspot.com",
  messagingSenderId: "789790710456",
  appId: "1:789790710456:web:4b5164b49c982ef7b3724d",
  measurementId: "G-FETRN68LEQ"
})

export const auth = app.auth()
export const firestore = app.firestore()
export const storage = app.storage()

getAnalytics(app);

export default app