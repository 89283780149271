import axios from "axios";

export default (token: string = '') => {
    // Handle signout
    if (token) {
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common.authorization;
    }

    // Add handler for catching all 401 errors
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {

                // Handle 401 error here
                // For example, you can redirect to a login page
                // or show an error message to the user
                window.location.href = '/login';
            }
            return Promise.reject(error);
        }
    );
};