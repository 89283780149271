import axios, { AxiosResponse } from 'axios'
import { UserProfile } from 'firebase/auth'
import { UpdateProfileDto, User } from 'stores/AuthStore'
import { OnboardingData } from 'stores/OnboardingStore'

export async function sendOnboardingData(data: OnboardingData) {
    return axios.post(`${process.env.REACT_APP_API_URL}/profile/onboarding`, data, { withCredentials: true })
}

export async function updateUserProfileOnBoardingDone(user: Partial<User>) {
    return axios.patch(`${process.env.REACT_APP_API_URL}/profile/${user.id}`, user, { withCredentials: true })
}

export async function updateUserProfile(updatedProfile: UpdateProfileDto) {
    return axios.patch(`${process.env.REACT_APP_API_URL}/profile/account/${updatedProfile.id}`, updatedProfile, { withCredentials: true })
}

export async function updateUserAvatar(file: FormData): Promise<AxiosResponse<string>> {
    return axios.patch(`${process.env.REACT_APP_API_URL}/profile/avatar`, file, { withCredentials: true })
}

// get user profile by ids 
export async function getUsersProfileById(ids: string[]): Promise<UserProfile> {
    return await axios.post(`${process.env.REACT_APP_API_URL}/profile/accounts`, { userIds: ids }, { withCredentials: true })
}