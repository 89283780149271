import googleSvg from "images/Google.svg";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BeatLoader, ClimbingBoxLoader } from "react-spinners";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { authStore } from "stores/AuthStore";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [email, setEmail] = useState('')
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (authStore.currentUser) {
      navigate('/')
    }
  }, [authStore.currentUser])

  const handleGoogleSignIn = async () => {
    authStore.setLoading(true);
    try {
      await authStore.googleSignIn()
    } catch (error) {
      console.log(error);
    } finally {
      authStore.setLoading(false);
    }
  }

  // Handler for input changes
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsLoading(true);
    await authStore.sendSignInLinkToEmailHandler(email)
    setIsLoading(false);
    setIsMagicLinkSent(true);
    setEmail('')
    toast.success('Verification email has been sent!');
  };

  return (
    <div className={`nc-PageLogin ${className} h-dvh`} data-nc-id="PageLogin">
      {authStore.loading ? <div className="flex items-center justify-center h-screen">
        <ClimbingBoxLoader color="#bb86fc" />
      </div> :
        <div>
          <Helmet>
            <title>Access Your Account || Booking React Template</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Access Your Account
            </h2>
            <div className="max-w-md mx-auto space-y-6">
              <span className="my-10 flex items-center text-l md:text-xl text-neutral-900 dark:text-neutral-100 text-center">
                Expand Your Network on the Go. Share your travels, connect with peers, and unlock new professional opportunities with every journey.
              </span>
              <div className="grid gap-3">
                {loginSocials.map((item, index) => (
                  <button
                    key={index}
                    className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      className="flex-shrink-0"
                      src={item.icon}
                      alt={item.name}
                    />
                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                      {item.name}
                    </h3>
                  </button>
                ))}
              </div>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              {/* FORM */}
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Magic Link
                  </span>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={handleEmailChange}
                  />
                  {isMagicLinkSent? <p className="mt-4 p-2">Verification email sent! Please check your inbox for a message with a magic link to verify your account. Simply click on the link to complete the verification process.</p>: null}
                </label>
                {/* <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" />
            </label> */}
                <ButtonPrimary loading={isLoading} type="submit">Continue</ButtonPrimary>
              </form>

              {/* ==== */}
              {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span> */}
            </div>
          </div>
        </div>}
    </div>
  );
};

export default observer(PageLogin)
