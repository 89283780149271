import { FlightCardProps } from "components/FlightCard/FlightCard";
import Heading2 from "components/Heading/Heading2";
import ItineraryCard from "components/ItineraryCard/ItineraryCard";
import { observer } from "mobx-react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { tripStore } from "stores/TripStore";
import TabFilters from "./TabFilters";
import Avatar from "shared/Avatar/Avatar";
import NoResultCard from "components/NoResult/GenericNoResultCard";

export interface SectionGridFilterCardProps {
  className?: string;
}


const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {

  const deleteTripById = (tripId: string) => {
    tripStore.deleteTripById(tripId)
  }

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="My trips"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {tripStore.myTrips.length} flights
            <span className="mx-2">·</span>
            round trip
            <span className="mx-2">·</span>2 Guests
          </span>
        }
      />
      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {tripStore?.myTrips?.length ? tripStore.myTrips.map((item, index) => (
          <ItineraryCard defaultOpen={false} key={index} data={item} deleteTripById={deleteTripById}/>
        )) : <NoResultCard displayName="Please add a new intneriry"/>
        }

        {/* <div className="flex mt-12 justify-center items-center">
          {tripStore?.myTrips?.length && <ButtonPrimary>Show more</ButtonPrimary>}
        </div> */}
      </div>
    </div>
  );
};

export default observer(SectionGridFilterCard)
