import { getUserTripById, postCommentToTrip } from 'api/trip';
import FooterNav from 'components/FooterNav';
import { PostDataType } from 'data/types';
import { Place, TripDetails } from 'interface/trip.interface';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, useParams } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import Textarea from 'shared/Textarea/Textarea';

const TripOverviewPage = () => {
  const { tripId } = useParams();
  const [trip, setTrip] = useState<TripDetails>();
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    const fetchTrip = async () => {
      const response = await getUserTripById(tripId);
      setTrip(response.data);
    };

    fetchTrip();
  }, [tripId]);


  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (!trip) {
      return
    }
    setIsSubmitting(true);
    try {
      const { data } = await postCommentToTrip(trip.id, comment);
      // Update the comments state
      setTrip((prevTrip) => ({
        ...(prevTrip as TripDetails),
        comments: prevTrip ? [...(prevTrip?.comments || []), data] : [comment],
      }));
      toast.success('Comment posted successfully');
    } catch (error) {
      toast.error('Failed to post comment');
      // handle error
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setComment('');
    }
  };

  if (!trip) {
    return (
      <div className="flex justify-center items-center h-screen">
        <MoonLoader color="blue" size={80} />
      </div>
    )
  }

  const renderHeader = () => {
    const startDate = moment(trip.startDate).format('MMMM Do, YYYY');
    const endDate = moment(trip.endDate).format('MMMM Do, YYYY');

    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Traveler" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {trip.title}
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {trip.description}
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
                imgUrl={trip.author.profile.avatar}
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    {trip.author.profile.fullName}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {startDate} - {endDate}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div> */}
          </div>
        </div>
      </header>
    );
  };

  const PlacesList = ({ places }: { places: Place[] }) => {
    if (places.length === 0) {
      return (
        <div className="relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
          No places to visit have been added to this trip.
        </div>
      );
    }

    return (
      <ol className="relative border-l border-gray-200 dark:border-gray-700">
        {places.map((place, index) => (
          <li key={place.id} className="mb-10 ml-6">
            <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
              <svg className="w-2.5 h-2.5 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
              </svg>
            </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
              {place.name}
            </h3>
            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              {place.date ? moment(place.date).format('MMMM Do, YYYY') : 'Date not specified'}
            </time>
            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
              {place.address || 'Address not available'}
            </p>
            {place.photoUrl && (
              <NcImage
                className="w-full rounded-lg"
                containerClassName="mb-4"
                src={place.photoUrl}
                alt={place.name}
              />
            )}
          </li>
        ))}
      </ol>
    );
  };


  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        {PlacesList(trip)}
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Comments ({trip?.commentsNum})
        </h3>
        <div className="nc-SingleCommentForm mt-5">
          <Textarea value={comment} onChange={handleInputChange} />
          <div className="mt-2 space-x-3">
            <ButtonPrimary loading={isSubmitting} disabled={isSubmitting} onClick={handleCommentSubmit}>{isSubmitting ? '' : 'Submit'}</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentLists = () => {
    if (trip?.comments?.length === 0) {
      return (
        <div className="max-w-screen-md mx-auto mt-10 text-center text-neutral-500 dark:text-neutral-400">
          No comments yet
        </div>
      );

    }

    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          {trip?.comments?.map((comment, index) => (
            <li key={index}>
              <Comment {...comment} />
              {/* <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              {comment.replies.map((reply, index) => (
                <li key={index}>
                  <Comment isSmall {...reply} />
                </li>
              ))}
            </ul> */}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };
console.log(trip);

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Single Blog || Booking React Template</title>
      </Helmet>
      {renderHeader()}
      {trip.photoUrls?.length ? <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src={trip.photoUrls[0]}
      /> : null}

      <div className="nc-SingleContent container space-y-10 py-16">
        {renderContent()}
        {renderTags()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/* {renderAuthor()} */}
        {renderCommentForm()}
        {renderCommentLists()}
      </div>
      <FooterNav />
    </div>
  );
};

export default TripOverviewPage;

