import axios, { AxiosResponse } from 'axios'
import { EventResponse, PredictHQEventResponse } from 'data/types'
import { IEventQueryParameters } from 'stores/EventsStore';
import { EventResponseDto } from 'stores/TicketmasterEventsStore';

export async function getEvents(eventQueryParameters: IEventQueryParameters): Promise<AxiosResponse<EventResponse>> {
   const url = `${process.env.REACT_APP_API_URL}/explore`;
 
   const params = new URLSearchParams({
     country: eventQueryParameters.country,
     start: eventQueryParameters.start,
     end: eventQueryParameters.end,
     page: eventQueryParameters.page,
     limit: eventQueryParameters.limit,
   });
 
   return axios.get<EventResponse>(`${url}?${params.toString()}`, { withCredentials: true });
 }

export async function getNearByEventForTripId(tripId: string | null): Promise<AxiosResponse<EventResponse>> {
   console.log(`[getNearByEventForTripId] ${tripId}`);

   const url = `${process.env.REACT_APP_API_URL}/explore`
   return axios.get(url, { withCredentials: true })
}

export const fetchEventsByCountry = async ({ startDate, endDate, country, page, limit }: { startDate?: string, endDate?: string, country?: string, page?: number, limit?: number }): Promise<{ events: PredictHQEventResponse[], page: number, limit: number, total: number }> => {
   const response = await axios.get(`${process.env.REACT_APP_API_URL}/explore/events/by-country`, {
      params: {
         startDate,
         endDate,
         country,
         page,
         limit
      }
   });
   return response.data;
};

export const fetchEvents = async (startDate?: string, endDate?: string, city?: string, page?: number, limit?: number): Promise<{ events: EventResponseDto[], page: number, limit: number, total: number }> => {
   const response = await axios.get(`${process.env.REACT_APP_API_URL}/explore/events`, {
      params: {
         startDate,
         endDate,
         city,
         page,
         limit
      }
   });
   return response.data;
};