import axios, { AxiosResponse } from 'axios';
import { BusinessTripState } from 'components/HeroSearchFormSmall/(flight-search-form)/FlightSearchForm';
import { PaginationResult } from 'interface/pagination';
import { TripDetails, TripDetailsResponse } from 'interface/trip.interface';

interface MyTripResponse {
    items: TripDetails[],
    meta: any
}

export interface AddPlaceBodyRequest {
    name: string;
    placeId: string;
    address: string;
    types: string[];
    date: Date;
    notes: string | null;
    files: string[] | null;
}

export interface UpdateTripBody {
    title?: string;
    description?: string;
    scheduleUrl?: string;
}

export const MATCHING_TRAVELERS_BASE_URL = `${process.env.REACT_APP_API_URL}/trip/matching-travelers/`

export async function sendTripDetailsToBackend(data: BusinessTripState) {
    return axios.post(`${process.env.REACT_APP_API_URL}/trip`, data, { withCredentials: true })
}

export async function addPlaceToTrip(tripId: string, placeObject: AddPlaceBodyRequest): Promise<AxiosResponse<TripDetails>> {
    return axios.post(`${process.env.REACT_APP_API_URL}/trip/add-place/${tripId}`, placeObject, { withCredentials: true })
}

export async function getActiveTrips(): Promise<AxiosResponse> {
    return axios.get(`${process.env.REACT_APP_API_URL}/trip`, { withCredentials: true })
}

export async function fetchMyTrips(): Promise<AxiosResponse<MyTripResponse>> {
    return axios.get(`${process.env.REACT_APP_API_URL}/trip/mine`, { withCredentials: true })
}

export async function getTripDetailsById(id: string | undefined): Promise<AxiosResponse> {
    return axios.get(`${process.env.REACT_APP_API_URL}/trip/details/${id}`, { withCredentials: true })
}

export async function fetchNextMonthArrivals(country: string, page: number, limit: number): Promise<{ items: TripDetailsResponse[], meta: any }> {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/trip/to-my-country-next-month`, {
        params: {
            country,
            page,
            limit,
        },
        withCredentials: true
    });
    return response.data;
}

export async function fetchNextMonthArrivalsByUrl(url: string): Promise<AxiosResponse<PaginationResult<TripDetailsResponse[]>>> {
    return axios.get(url, { withCredentials: true })
}

export async function fetchMatchingTravelers(url: string): Promise<AxiosResponse> {
    return axios.get(url, { withCredentials: true })
}

export async function fetchLocalsAtDestination(tripId: string): Promise<AxiosResponse> {
    return axios.get(`${process.env.REACT_APP_API_URL}/trip/locals-at-destination/${tripId}`, { withCredentials: true })
}

export async function deletePlaceById(placeId: string): Promise<AxiosResponse> {
    return axios.delete(`${process.env.REACT_APP_API_URL}/trip/delete-place/${placeId}`, { withCredentials: true });
}

export async function addScheduleUrl(tripId: string, url: string): Promise<AxiosResponse> {
    return axios.post(`${process.env.REACT_APP_API_URL}/trip/add-schedule-url/${tripId}`, { url }, { withCredentials: true });
}

export async function removeScheduleUrl(tripId: string): Promise<AxiosResponse> {
    return axios.delete(`${process.env.REACT_APP_API_URL}/trip/remove-schedule-url/${tripId}`, { withCredentials: true });
}

export async function deleteTripById(tripId: string): Promise<AxiosResponse> {
    return axios.delete(`${process.env.REACT_APP_API_URL}/trip/delete-trip/${tripId}`, { withCredentials: true });
}

export function getUserTripById(tripId: string | undefined) {
    return axios.get(`${process.env.REACT_APP_API_URL}/trip/user/${tripId}`, { withCredentials: true });
}

export async function postCommentToTrip(tripId: string, comment: string): Promise<AxiosResponse> {
    return axios.post(`${process.env.REACT_APP_API_URL}/trip/comment/${tripId}`, { comment }, { withCredentials: true });
}

//create an update trip function
export async function updateTrip(tripId: string, data: UpdateTripBody): Promise<AxiosResponse> {
    return axios.put(`${process.env.REACT_APP_API_URL}/trip/update-trip/${tripId}`, data, { withCredentials: true });
}