import { last } from "lodash";
import React from "react";
import { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const CheckIcon = () => (
  <svg className="w-3.5 h-3.5 text-blue-600 lg:w-4 lg:h-4 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
  </svg>
);

const UserIcon = ({ isCurrent }: { isCurrent: boolean }) => (
  <svg
    className={`w-8 h-8 ${isCurrent ? 'text-blue-600' : 'text-gray-500'} lg:w-5 lg:h-5 dark:text-gray-100`}
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
  >
    <path d="M360-390q-21 0-35.5-14.5T310-440q0-21 14.5-35.5T360-490q21 0 35.5 14.5T410-440q0 21-14.5 35.5T360-390Zm240 0q-21 0-35.5-14.5T550-440q0-21 14.5-35.5T600-490q21 0 35.5 14.5T650-440q0 21-14.5 35.5T600-390ZM480-160q134 0 227-93t93-227q0-24-3-46.5T786-570q-21 5-42 7.5t-44 2.5q-91 0-172-39T390-708q-32 78-91.5 135.5T160-486v6q0 134 93 227t227 93Zm0 80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-54-715q42 70 114 112.5T700-640q14 0 27-1.5t27-3.5q-42-70-114-112.5T480-800q-14 0-27 1.5t-27 3.5ZM177-581q51-29 89-75t57-103q-51 29-89 75t-57 103Zm249-214Zm-103 36Z" />
  </svg>
);

const InfoIcon = ({ isCurrent }: { isCurrent: boolean }) => (
  <svg className={`w-5 h-5 ${isCurrent ? 'text-blue-600' : 'text-gray-500'}  lg:w-5 lg:h-5 dark:text-gray-100`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
    <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0-2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0-2Z" />
  </svg>
);

const ClipboardIcon = ({ isCurrent }: { isCurrent: boolean }) => (
  <svg className={`w-4 h-4 ${isCurrent ? 'text-blue-600' : 'text-gray-500'}  lg:w-5 lg:h-5 dark:text-gray-100`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
    <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2ZM7 2h4v3H7V2Zm5.7 8.289-3.975 3.857a1 1 0 0 1-1.393 0L5.3 12.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435Z" />
  </svg>
)

const InerestsIcon = ({ isCurrent }: { isCurrent: boolean }) => (
  <svg className={`w-8 h-8 ${isCurrent ? 'text-blue-600' : 'text-gray-500'}  lg:w-5 lg:h-5 dark:text-gray-100`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m80-520 200-360 200 360H80Zm200 400q-66 0-113-47t-47-113q0-67 47-113.5T280-440q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T360-280q0-33-23.5-56.5T280-360q-33 0-56.5 23.5T200-280q0 33 23.5 56.5T280-200Zm-64-400h128l-64-115-64 115Zm304 480v-320h320v320H520Zm80-80h160v-160H600v160Zm80-320q-57-48-95.5-81T523-659q-23-25-33-47t-10-47q0-45 31.5-76t78.5-31q27 0 50.5 12.5T680-813q16-22 39.5-34.5T770-860q47 0 78.5 31t31.5 76q0 25-10 47t-33 47q-23 25-61.5 58T680-520Zm0-105q72-60 96-85t24-41q0-13-7.5-21t-20.5-8q-10 0-19.5 5.5T729-755l-49 47-49-47q-14-14-23.5-19.5T588-780q-13 0-20.5 8t-7.5 21q0 16 24 41t96 85Zm0-78Zm-400 45Zm0 378Zm400 0Z" /></svg>
)

export interface CommonLayoutProps {
  index: string;
  nextBtnText?: string;
  children: React.ReactNode;
  onContinue?: () => void;
  onBack?: () => void;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "1",
  children,
  nextBtnText,
  onContinue,
  onBack
}) => {
  index = '1'
  console.log(index);

  const StepItem = ({ completed, icon, last }: { completed: boolean, icon: React.ReactNode, last: boolean }) => (
    <li className={`flex items-center ${last ? null : completed ? 'w-full text-blue-600 dark:text-blue-500 after:content-[""] after:w-full after:h-1 after:border-b after:border-blue-100 after:border-4 after:inline-block dark:after:border-blue-800' : 'w-full after:content-[""] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block dark:after:border-gray-700'}`}>
      <span className={`flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0 ${completed ? 'bg-blue-100 dark:bg-blue-800' : 'bg-gray-100 dark:bg-gray-700'}`}>
        {icon}
      </span>
    </li>
  );

  const Steps = ({ steps }: { steps: any[] }) => (
    <ol className="flex items-center justify-between w-full">
      {steps.map((step, index) => (
        <StepItem key={index} completed={step.completed} icon={step.icon} last={step.last} />
      ))}
    </ol>
  );

  const stepsData = [
    { completed: index > "2", icon: <UserIcon isCurrent={index === "2"} /> },
    { completed: index > "3", icon: <InfoIcon isCurrent={index === "3"} /> },
    { completed: index > "4", icon: <ClipboardIcon isCurrent={index === "4"} /> },
    { completed: false, icon: <InerestsIcon isCurrent={index === "5"} />, last: true },
  ];

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-8 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        {/* <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 3
          </span>
        </div> */}

        {/* <Steps steps={stepsData} /> */}


        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          {index !== '01' && <ButtonSecondary onClick={onBack}>Go back</ButtonSecondary>}
          <ButtonPrimary onClick={onContinue}>
            {nextBtnText || "Continue"}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;
