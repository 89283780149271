import axios, { AxiosResponse } from 'axios'
import { User } from 'stores/AuthStore';
import { UserProfileDTO } from './dto/get-user-data-by-id.dto';

export async function getUser(): Promise<AxiosResponse<User>> {
    return axios.get(`${process.env.REACT_APP_API_URL}/authentication`, { withCredentials: true })
}

export async function getMemberDataById(userId: string | undefined): Promise<AxiosResponse<UserProfileDTO>>{
    if (!userId) {
        throw new Error('User id is required');
    }
    return axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}`, { withCredentials: true })
}

export async function getMemberDataByProfileId(id: number) {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/member-profile/${id}`, { withCredentials: true })
}

export async function searchContacts(url: string, data: any) {
    const baseURL = url || `${process.env.REACT_APP_API_URL}/users/contacts`
    return axios.post(baseURL, data, { withCredentials: true })
}

export async function deleteAccount() {
    return axios.delete(`${process.env.REACT_APP_API_URL}/users`, { withCredentials: true })
}