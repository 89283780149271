import { MapPinIcon } from "@heroicons/react/24/outline";
import LocationAutocomplete from "components/Common/LocationAutocomplete";
import { observer } from "mobx-react";
import { FC, Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { onboardingStore } from "stores/OnboardingStore";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { Dialog, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { authStore } from "stores/AuthStore";
import Label from "components/Label/Label";
export interface OnBoardingStep1Props { }

const Languages = [
  { "name": "English", "nativeName": "English", "defaultChecked": true },
  { "name": "Spanish", "nativeName": "Español" },
  { "name": "French", "nativeName": "Français" },
  { "name": "Chinese", "nativeName": "中文" },
  { "name": "Russian", "nativeName": "Русский" },
  { "name": "Arabic", "nativeName": "العربية" },
  { "name": "Hindi", "nativeName": "हिन्दी" },
  { "name": "Bengali", "nativeName": "বাংলা" },
  { "name": "Portuguese", "nativeName": "Português" },
  { "name": "Japanese", "nativeName": "日本語" },
  { "name": "Hebrew", "nativeName": "עברית" },
]


const OnBoardingStep1: FC<OnBoardingStep1Props> = observer(() => {

  const { currentUser } = authStore
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);
  //
  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);
  // Extract the address from the store
  const currentAddress = onboardingStore.location.details?.formatted_address || '';

  async function success(pos: { coords: any; }) {
    const crd = pos.coords;

    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
    const gocoder = new google.maps.Geocoder();
    gocoder.geocode({ location: { lat: crd.latitude, lng: crd.longitude } }, (results, status) => {
      console.log(results);
      
      if (results) {
        onboardingStore.setLocation(results[0])
      }
    })
  }

  function error(err: { code: any; message: any; }) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    onboardingStore.setFullName(currentUser?.profile.fullName || '')
  }, [currentUser?.profile.fullName])


  const handleLocationChange = (locationDetails: google.maps.GeocoderResult) => {
    onboardingStore.setLocation(locationDetails);
  };

  const handleFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onboardingStore.setFullName(e.target.value)
  }

  const handleContinue = () => {
    if (onboardingStore.fullName && onboardingStore.locationObject.details) {
      onboardingStore.setOnboardingNextStep()
    } else {
      toast.error('Please complete the form')
    }
  }

  const renderMoreFilterItem = (
    data: {
      name: string;
      nativeName?: string;
      defaultChecked?: boolean;
    }[]
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-y-7 gap-x-4 sm:gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.nativeName}
              label={item.name}
              defaultChecked={onboardingStore.languages.includes(item.name)}
              onChange={(checked) => onboardingStore.setLanguages(checked, item.name)}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.nativeName}
              label={item.name}
              defaultChecked={onboardingStore.languages.includes(item.name)}
              onChange={(checked) => onboardingStore.setLanguages(checked, item.name)}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderChooseLang = () => {
    return (
      <div className="space-y-4">
        {<Label>Language</Label>}

        <div
          className={`flex items-center justify-center px-4 py-3 sm:px-6 text-sm rounded-full border text-neutral-700 dark:text-neutral-200 cursor-pointer`}
          onClick={openModalMoreFilter}
        >

          <span>Choose Your Languages</span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium ">
                          Choose Your Languages
                        </h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(Languages)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        onboardingStore.clearLanguages()
                        closeModalMoreFilter()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <CommonLayout
      index="2"
      onContinue={handleContinue}
    >
      <>
        {/* <h2 className="text-2xl font-semibold">Basic Profile Information</h2> */}
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Display Name"
            // desc="Enter a unique display name that will be visible to other users. This could be your real name, a nickname, or any preferred identifier."
            className="space-y-4"
          >
            <Input name="displayName" placeholder="Enter Display Name" onChange={handleFullName} value={onboardingStore.fullName} />
          </FormItem>

          <FormItem
            label="Home base"
            // desc="Select the location you consider as your home base. This could be your current city, hometown, or any place you identify as your primary residence."
            className="space-y-4"
          >
            <LocationAutocomplete
              placeHolder="Select your home base"
              address={currentAddress}
              onLocationSelect={handleLocationChange}
            />
            <ButtonSecondary
              className="mt-4 w-full"
              onClick={() => navigator.geolocation.getCurrentPosition(success, error, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
              })}>
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span className="ml-3">Use current location</span>
            </ButtonSecondary>
          </FormItem>
          {renderChooseLang()}
          {/* <span className="block mt-3 text-xs text-neutral-500 dark:text-neutral-400 ">
          {"Select the location you consider as your home base. This could be your current city, hometown, or any place you identify as your primary residence."}
        </span> */}
        </div>
      </>
    </CommonLayout>
  );
})

export default OnBoardingStep1;
