import { getEvents } from "api/explore";
import Heading2 from "components/Heading/Heading2";
import EmptyResponsePage from "containers/EmptyResponsePage/EmptyResponsePage";
import { EventResponse, ITransformedEvent } from "data/types";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { FC, ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authStore } from "stores/AuthStore";
import { tripStore } from "stores/TripStore";
import EventCard from "./EventCard";
import TabFilters from "./TabFilters";
import MoonLoaderComponent from "components/Loaders/MoonLoader";
import { formatDateRange } from "utils/dates";

export interface SectionEventsFilterCardProps {
  className?: string;
}

const SectionEventsFilterCard: FC<SectionEventsFilterCardProps> = ({
  className = "",
}) => {

  const [events, setEvents] = useState<ITransformedEvent[]>([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(-1);
  const [totalEvents, setTotalEvents] = useState(-1);
  const [currentTrip, setCurrentTrip] = useState({
    start: '',
    end: '',
    country: ''
  });

  const { sourceId } = useParams();

  useEffect(() => {
    const fetchTripData = async () => {
      if (sourceId === 'localEvents') {
        const start = moment().format('YYYY-MM-DD');
        const end = moment().add(1, 'M').format('YYYY-MM-DD');
        const country = authStore.currentUser?.profile?.country || '';
        setCurrentTrip({ start, end, country });
      } else {
        let trip = tripStore.myTrips.find(trip => trip.id === sourceId);

        if (!trip && sourceId) {
          trip = await tripStore.getTripById(sourceId);
        }

        if (trip) {
          const { startDate: start, endDate: end, toCountry: country } = trip;
          setCurrentTrip({ start, end, country });
        } else {
          setCurrentTrip({
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(1, 'M').format('YYYY-MM-DD'),
            country: authStore.currentUser?.profile?.country || ''
          });
        }
      }
    };

    fetchTripData();
  }, [sourceId]);

  async function loadMoreEvents(start: string, end: string, country: string, page = '1', limit = '10') {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getEvents({ start, end, country, page, limit });
      setEvents([...events, ...response.data.data]);
      setPage(response.data.meta.currentPage + 1)
      setTotalPages(response.data.meta.totalPages)
      setTotalEvents(response.data.meta.totalItems)
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      error = error?.message || 'An error occurred while fetching events';
    }
  }

  useEffect(() => {

    if (currentTrip.country) {
      loadMoreEvents(currentTrip.start, currentTrip.end, currentTrip.country);
    }
  }, [currentTrip]);

  if (isLoading) {
    return <MoonLoaderComponent />
  }

  const subHeading: ReactNode = (
    <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
      {totalEvents} events
      <span className="mx-2">·</span>
      {formatDateRange(currentTrip.start, currentTrip.end)}
    </span>
  )

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 heading={`Events in ${currentTrip.country}`} subHeading={subHeading}></Heading2>
      {events.length === 0 ? <EmptyResponsePage /> :
        <>
          <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {events.map((event) => (
              <EventCard key={event.id} data={event} />
            ))}
          </div>
        </>}
    </div>
  );
};

export default observer(SectionEventsFilterCard)
