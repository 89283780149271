import { VentureCapitalFirmFilterDto } from "api/vc-firms";
import WidgetHeading1 from "containers/BlogPage/WidgetHeading1";
import { FC } from "react";
import CardVcFirm from "./CardVcFirm";

export interface WidgetVentureCapitalProps {
  className?: string;
  vcs?: VentureCapitalFirmFilterDto[];
}

const WidgetVentureCapital: FC<WidgetVentureCapitalProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  vcs = [],
}) => {
  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
      data-nc-id="WidgetCategories"
    >
      <WidgetHeading1
        title="✨ VC Firms"
        viewAll={{ label: "View all", href: "/vc-firms" }}
      />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {vcs.map((vc, i) => (
            <CardVcFirm
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={i}
              vc={vc}
              size="normal"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetVentureCapital;
