import { HOBBIES_CATEGORIES } from "data/taxonomies";
import { InterestsType } from "data/types";
import { observer } from "mobx-react";
import { FC } from "react";
import { goals, onboardingStore } from "stores/OnboardingStore";


export interface WidgetTagsProps {
  className?: string;
  tags?: InterestsType[];
}

export const rolesColorPlates = ['#BFD9DA', '#EEE4AF', '#ECD2C4', '#DBE2C6', '#DACEE0', '#F1D4A9', '#D6C5B6', '#B4D6B4', '#F3BBBB', '#E5F3BB', '#BDD2F3', '#ECC4D2', '#D3CFF', '#CFFEDA', '#F2CFFE', '#FBCDB3', '#BBF3E6'];

const InterestsWidgetTags: FC<WidgetTagsProps> = observer(({ className = "", tags = HOBBIES_CATEGORIES }) => {

  const toggleGoal = (goalName: string) => {
    onboardingStore.setGoals(goalName);
  };


  return (
    <div
      className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetTags"
    >
      <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
        <div className="grid grid-cols-2 gap-4">
          {goals.map((goal) => (
            <button
              key={goal.name}
              className={`flex flex-col items-center justify-center w-30 h-35 p-4 border rounded-md transition-colors duration-200 ${onboardingStore.goals.includes(goal.name) ? 'dark:bg-neutral-800 border-blue-700 rounded' : 'border-gray-300'}`}
              onClick={() => toggleGoal(goal.name)}
            >
              <span className="text-2xl mb-2">{goal.icon}</span>
              <span className="text-center text-sm">{goal.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
})

export default InterestsWidgetTags;


