import { log } from "console";
import { TwMainColor } from "data/types";
import { TripDetailsResponse } from "interface/trip.interface";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import BadgeScore from "shared/BadgeScore/BadgeScore";
import DropSvg from "svg/drop";
import EcoSvg from "svg/eco";
import FireSvg from "svg/fire";
import { formatDateRange } from "utils/dates";

export interface CardTripBoxProps {
  className?: string;
  tripDetails: TripDetailsResponse;
  index?: number;
}

const CardTripBox: FC<CardTripBoxProps> = ({
  className = "",
  tripDetails: trip,
  index,
}) => {

  const { id, displayName, avatar, country, startDate, endDate, industry, matchScore } = trip;

  const getSvgByScore = (matchScore: number) => {
    if (matchScore >= 7) {
      return { SvgComponent: FireSvg, color: 'red' as TwMainColor }; // High score
    } else if (matchScore >= 4) {
      return { SvgComponent: EcoSvg, color: 'green' as TwMainColor }; // Medium score
    } else {
      return { SvgComponent: DropSvg, color: 'blue' as TwMainColor }; // Low score
    }
  };

  const { SvgComponent, color } = getSvgByScore(0);

  return (
    <Link
      to={`/user/${id}`}
      className={`nc-CardTripBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7 h-64 w-60 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardTripBox"
    >
      {index && (
        <BadgeScore
          className="absolute left-3 top-3"
          name=""
          matchScore={matchScore}
        />
      )}
      <Avatar
        sizeClass="w-20 h-20 text-2xl"
        radius="rounded-full"
        imgUrl={avatar}
        userName={displayName}
      />
      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span className="line-clamp-1">{displayName}</span>
        </h2>
        <span className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}>{country}</span>
        <span className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}>{formatDateRange(startDate, endDate)}</span>
      </div>
      <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
        <span className="text-xs font-medium pt-[1px]">
          {industry || 4.9}
        </span>
        {/* <StarIcon className="w-5 h-5 text-amber-500 ml-2 " /> */}
      </div>
    </Link>
  );
};

export default CardTripBox;
