import LocationAutocomplete from "components/Common/LocationAutocomplete";
import FooterNav from "components/FooterNav";
import Label from "components/Label/Label";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { authStore, UpdateProfileDto } from "stores/AuthStore";
import CommonLayout from "./CommonLayout";
import Autocomplete from "components/AutoComplete/AutoComplete";
import { Metadata } from "containers/OnBoarding/OnBoardingStep2";
import { getMetadata } from "api/metadata";

export interface AccountPageProps {
  className?: string;
}


const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { currentUser } = authStore
  const [metadata, setMetdata] = useState<Metadata>({
    industries: [],
    roles: []
  })
  // State to temporarily hold input changes before submitting
  const [formFields, setFormFields] = useState<UpdateProfileDto>({
    id: currentUser?.id || "",
    fullName: currentUser?.profile.fullName,
    companyName: currentUser?.profile.companyName || "",
    industry: currentUser?.profile.industry,
    role: currentUser?.profile.role,
    placeDescription: currentUser?.profile.placeDescription,
    interests: currentUser?.profile.interests,
    avatar: currentUser?.profile.avatar,
    birthDate: currentUser?.profile.birthDate,
    phone: currentUser?.profile.phone || "",
    bio: currentUser?.profile.bio || "",
  });

  useEffect(() => {
    getMetadata().then(res => {
      const { data } = res
      setMetdata(data)
    })
  }, [])


  // Generic handler for input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handler for submitting the updated profile
  const handleSubmit = () => {
    try {
      // Call the updateUserProfile method in authStore with formFields
      authStore.updateProfile(formFields).then(() => toast.success("Profile updated successfully"))
    } catch (error) {
      toast.error("An error occurred. Please try again");
    }
  };

  const handleLocationResult = (locationDetails: google.maps.GeocoderResult) => {
    console.log(locationDetails);
    const { formatted_address } = locationDetails;
    const placeDescription = formatted_address;

    setFormFields((prev) => ({
      ...prev,
      placeDescription,
      locationDetails
    }));
  }

  const changeAvatar = (file: File) => {
    // validate file size and type 
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size should be less than 1MB");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    authStore.updateAvatar(formData).then(() => toast.success("Avatar updated successfully")).catch(() => toast.error("An error occurred. Please try again"))
  }

  function handleChanges(key: string, newRole: string): void {
    setFormFields((prev) => ({
      ...prev,
      [key]: newRole,
    }));
  }

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Account infomation</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={formFields?.avatar} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) {
                      changeAvatar(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              {/* <div>
                <Label>Name</Label>
                <Input className="mt-1.5" defaultValue={currentUser?.profile.fullName} name="fullName" onChange={(e) => {console.log(e.target.name)}} />
              </div> */}
              {/* ---- */}
              {/* <div>
                <Label>Gender</Label>
                <Select className="mt-1.5">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">None</option>
                </Select>
              </div> */}
              {/* ---- */}
              <div>
                <Label>Username</Label>
                <Input
                  className="mt-1.5"
                  defaultValue={formFields?.fullName || ''}
                  name="fullName"
                  onChange={handleInputChange} />
              </div>
              {/* ---- */}
              {/* <div>
                <Label>Email</Label>
                <Input className="mt-1.5" defaultValue={formFields?.email} disabled />
              </div> */}
              {/* ---- */}
              <div className="max-w-lg">
                <Label>Date of birth</Label>
                <Input
                  className="mt-1.5"
                  type="date"
                  defaultValue={moment(formFields?.birthDate).format("YYYY-MM-DD")}
                  name="birthDate"
                  onChange={handleInputChange}
                />
              </div>
              {/* ---- */}
              <div>
                <Label>Address</Label>
                <LocationAutocomplete
                  address={formFields?.placeDescription || ""}
                  onLocationSelect={handleLocationResult}
                />
              </div>
              {/* ---- */}
              <div>
                <Label>Role</Label>
                <Autocomplete defaultValue={formFields?.role} options={metadata.roles.map(r => r.value)} placeholder="Select your role" onChange={(val) => handleChanges("role", val)} />
              </div>
              <div>
                <Label>Industry</Label>
                <Autocomplete defaultValue={formFields?.industry} options={metadata.industries.map(r => r.value)} placeholder="Select your industry" onChange={(val) => handleChanges("industry", val)} />
              </div>
              {/* <div>
                <Label>Phone number</Label>
                <Input
                  className="mt-1.5"
                  defaultValue={formFields?.phone}
                  name="placeDescription"
                  onChange={handleInputChange}
                />
              </div> */}
              {/* ---- */}
              <div>
                <Label>About you</Label>
                <Textarea
                  className="mt-1.5"
                  defaultValue={formFields?.bio}
                  name="bio"
                  onChange={handleInputChange}
                />
              </div>
              <div className="pt-2">
                <ButtonPrimary onClick={handleSubmit}>Update info</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
      <FooterNav />
    </div>
  );
};

export default observer(AccountPage)
