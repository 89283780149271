import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import CommonLayout from "./CommonLayout";
import { onboardingStore } from "stores/OnboardingStore";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Create Your Profile",
    desc: "Sign up quickly and provide detailed information to enhance your matching experience.",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Specify Your Networking Preferences",
    desc: "Define the type of business partners you want to connect with to receive tailored matches.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Let Our AI Do the Rest",
    desc: "Our advanced AI algorithm will find the best matches for you, making networking effortless.",
  },
];

const handleContinue = () => {
    onboardingStore.setOnboardingNextStep()
}

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="Keep calm & travel on">
        How it works
      </Heading>
      <div className="mt-10 relative grid md:grid-cols-3 gap-5">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[100px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[100px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-4 max-w-[100px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center mt-auto mb-5">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>        
      <div className="flex justify-center pb-8 pt-8">
        <ButtonPrimary className="md:w-1/4 sm:w-full" onClick={handleContinue}>Continue</ButtonPrimary>
      </div>
    </div>

  );
};

export default observer(SectionHowItWork)
