import React, { FC, useState } from 'react';
import Input from 'shared/Input/Input';

export interface AutocompleteProps {
    defaultValue?: string
    options: string[],
    placeholder?: string,
    inputClass?: string
    onChange: (value: string) => void
}

const Autocomplete: FC<AutocompleteProps> = ({ defaultValue = '', options, placeholder, inputClass, onChange }) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
    const [showList, setShowList] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        onChange(value);
        if (value) {
            const matches = options.filter(option => option.toLowerCase().startsWith(value.toLowerCase()));
            setFilteredOptions(matches);
            setShowList(true);
        } else {
            setFilteredOptions([]);
            setShowList(false);
        }
    };

    const handleSelect = (option: string) => {
        onChange(option);
        setInputValue(option);
        setFilteredOptions([]);
        setShowList(false);
    };

    return (
        <div className="relative">
            <Input
                type="text"
                // className={`w-full px-4 py-2 border border-gray-300 rounded-md ${inputClass}`}
                placeholder={placeholder}
                value={inputValue}
                onChange={handleChange}
            />
            {showList && filteredOptions.length > 0 && (
                <div className="absolute w-full mt-1 rounded-md max-h-60 overflow-y-auto z-10 bg-slate-50">
                    {filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            className="px-4 py-2 cursor-pointer dark:bg-neutral-900"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Autocomplete;
